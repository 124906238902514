<template>
<v-main class="ml-0 mt-2 mr-2">
    <div class="ml-2">

        <v-card max-width="100%" elevation="1">
            <v-alert style="max-height: 47px;!important" color="topo_menu" class="text-left">
                <v-breadcrumbs :items="itemsBread">
                    <template v-slot:divider>
                        <v-icon>mdi-forward</v-icon>
                    </template>
                </v-breadcrumbs>
            </v-alert>

            <v-row class="mx-auto mt-0 ml-0 pa-5">
                <v-col cols="12" xl="2" lg="2" md="2" sm="2" xs="3">
                    <v-text-field v-model="grupo.codigo" ref="codigo" name="codigo" label="Código" value filled clearable :name="`codigo_${Math.random()}`" hide-details @keyup.enter="mudaCampo('descricao')"></v-text-field>
                </v-col>
                <v-col cols="12" xl="8" lg="8" md="6" sm="8" xs="3">
                    <v-text-field v-model="grupo.descricao" ref="descricao" name="Descricao" label="Nome" value filled clearable :name="`descricao_${Math.random()}`" hide-details @keyup.enter="mudaCampo('status')"></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col offset="0" cols="12" xl="2" lg="4" md="4" sm="4" xs="6">
                    <v-autocomplete transition="fab-transition" ref="status" :items="listaStatus" v-model="grupo.id_fin_status" name="status" label="Status" item-value="id_fin_status" item-text="descricao" @change="getStatus" value filled clearable hide-details></v-autocomplete>
                </v-col>

                <v-col v-show="!editar" cols="12" xl="12" lg="9 " md="8" sm="12" xs="12">
                    <v-switch false-value="F" true-value="T" dense flat inset hide-details="" v-model="grupo.chk_nao_res_opera" :label="`Não participa do Resultado Operacional`"></v-switch>
                </v-col>

                <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
                    <v-btn small @click="salvar()" color="success">
                        <v-icon left>
                            mdi-content-save-outline
                        </v-icon>
                        Salvar
                    </v-btn>
                </v-col>
                <v-col v-show="editar" cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
                    <v-btn small @click="cancelEdit()" color="warning">

                        Cancelar
                    </v-btn>
                </v-col>
            </v-row>

        </v-card>
        <v-card class="mt-5">
            <v-row no-gutters>
                <v-col style="padding:6px 0px 6px 0px!important" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">

                    <v-data-table :headers="headers" :items="grupos" :loading="false" :search="search" :items-per-page="registrosPage" group-by="status" sort-by="status" show-group-by dense no-data-text="Nenhum resultado encontrado!" no-results-text="Nenhum resultado encontrado!" class="elevation-1">
                        <template v-slot:top="{ item }">
                            <v-radio-group @change="buscaGrupos()" row v-model="tipo">
                                <v-radio  label="Somente ativos" value="ativos" ></v-radio>
                                <v-radio label="Todos" value="todos"></v-radio>
                            </v-radio-group>
                            
                        </template>
                        <template v-slot:item.chk_nao_res_opera="{ item }">
                            <v-switch @change="naoOperaRes(item.id_grupo_dep,item.chk_nao_res_opera)" false-value="F" true-value="T" dense flat inset hide-details="" v-model="item.chk_nao_res_opera"></v-switch>
                        </template>
                        <template v-slot:item.chk_ativo="{ item }">

                            <v-switch dense flat inset hide-details="" v-model="item.chk_ativo" @change="mudarStatus(item.id_grupo_dep,item.chk_ativo)"></v-switch>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon color="primary" small class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                            <!--<v-icon color="error" small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>-->
                        </template>

                    </v-data-table>

                </v-col>
            </v-row>
        </v-card>
    </div>
    <v-dialog v-model="dialogDelete" max-width="600px">
        <v-card>
            <v-card-title class="">Tem certeza que deseja excluir este Registro?</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-snackbar :timeout="3000" :color="corMsg" :multi-line="'multi-line'" :vertical="'vertical'" :top="true" v-model="mostraMsg">
        {{ textSnack }}
    </v-snackbar>
</v-main>

</v-container>
</template>

    
<script>
export default {
    props: {
        source: String,
    },
    mounted() {
        this.buscaGrupos();
        this.buscaStatus();
        this.mudaCampo("codigo");
    },
    data: () => ({
        registrosPage: 100,
        mostraMsg: false,
        textSnack: "",
        corMsg: "",
        search: "",
        identificacao: "",
        tipo:'ativos',
        grupo: {
            id_grupo_dep: "",
            codigo: "",
            descricao: "",
            chk_nao_res_opera: false,
            id_fin_status: null,
            chk_ativo: ""
        },

        items: [],
        headers: [],
        itemsBread: [{
                text: "Cadastros",
                disabled: true,
                href: "breadcrumbs_dashboard",
            },
            {
                text: "Grupos Financeiros",
                disabled: false,
                href: "/dashboard/grupo_financeiro",
            },
        ],
        dialog: false,
        grupos: [],
        editar: false,
        listaStatus: [],
        headers: [

            {
                text: "Descrição",
                value: "descricao",
                groupable: false,
            },
            {
                text: "Status",
                value: "status",
                groupable: true,
                sortable:false,
            },
            {
                text: "Ativo",
                value: "chk_ativo",
                groupable: false,
                sortable:false,
            },
            {
                text: "Não participa Res.Operacional",
                value: "chk_nao_res_opera",
                width: "100",
                groupable: false,
                sortable:false,
            },

            {
                text: "Ações",
                value: "actions",
                sortable: false
            },
        ],
        selDel: "",
        selEdit: "",
        dialogDelete: false,
    }),
    methods: {
        getStatus(val) {
            console.log(val)
        },

        naoOperaRes(id, status) {

            axios
                .post(
                    `/fin/grupo/naoOperaRes`, {
                        id: id,
                        chk_nao_res_opera: status,
                        cnpj: window.sessionStorage.getItem("cnpj")
                    }

                )
                .then((response) => {
                    this.selDel = "";

                    
                        this.textSnack = "Alterado com sucesso !";
                        this.corMsg = "success";
                 

                    this.mostraMsg = true;

                    //this.grupos = response.data;
                });
        },
        mudarStatus(id, status) {

            axios
                .post(
                    `/fin/grupo/status`, {
                        id: id,
                        chk_status: status,
                        cnpj: window.sessionStorage.getItem("cnpj")
                    }

                )
                .then((response) => {
                    this.selDel = "";

                    if (status) {
                        this.textSnack = "Ativado com sucesso !";
                        this.corMsg = "success";
                    } else {
                        this.textSnack = "Desativado com sucesso!";
                        this.corMsg = "error";
                    }

                    this.mostraMsg = true;

                    //this.grupos = response.data;
                });
        },
        editItem(item) {
            this.grupo = item;
            this.editar = true;
        },
        cancelEdit() {
            this.editar = false;
            this.limpaCampos()
            this.buscaGrupos();

        },
        deleteItemConfirm() {
            //  this.listaLancamentos.splice(this.editedIndex, 1);
            this.dialogDelete = false;
            axios
                .post(
                    `/fin/grupo/remove`, {
                        id: this.selDel,
                        cnpj: window.sessionStorage.getItem("cnpj")
                    }

                )
                .then((response) => {
                    this.selDel = "";
                    this.buscaGrupos();
                    this.textSnack = "Exclusão feito com sucesso!";

                    this.mostraMsg = true;
                    this.corMsg = "success";
                    //this.grupos = response.data;
                });
        },
        limpaCampos() {
            this.grupo.id_grupo_dep = ""
            this.grupo.codigo = ""
            this.grupo.descricao = ""
            this.grupo.chk_nao_res_opera = false
            this.grupo.id_fin_status = null

        },
        deleteItem(item) {

            this.selDel = item.id;
            this.dialogDelete = true;
        },
        mudaCampo(campo) {
            eval("this.$refs." + campo + ".focus()");
        },
        validacao() {

            /* if (this.grupo.codigo == "") {
                 this.textSnack = "Informe o Código";
                 this.mudaCampo("codigo");
                 this.mostraMsg = true;
                 this.corMsg = "error";
                 return false;
             }*/
            if (this.grupo.descricao == "") {
                this.textSnack = "Preencha a descrição";
                this.mudaCampo("descricao");
                this.mostraMsg = true;
                this.corMsg = "error";
                return false;
            }
            if (this.grupo.id_fin_status == null) {
                this.textSnack = "Selecione um Status";
                this.mudaCampo("status");
                this.mostraMsg = true;
                this.corMsg = "error";
                return false;
            }

            return true
        },

        buscaGrupos() {
            axios
                .post(
                    `/fin/grupo/lista`, {
                        cnpj: window.sessionStorage.getItem("cnpj"),
                        chk_ativo:this.tipo
                    },

                )
                .then((response) => {
                    this.grupos = response.data;
                });
        },
        buscaStatus() {
            axios
                .post(
                    `/fin/status/lista`, {
                        cnpj: window.sessionStorage.getItem("cnpj"),
                    },

                )
                .then((response) => {
                    this.listaStatus = response.data;
                });
        },
        salvar() {

            if (!this.validacao()) {

                return false;
            }
            axios
                .post(
                    `/fin/grupo`, {
                        cnpj: window.sessionStorage.getItem("cnpj"),
                        grupo: this.grupo,
                    }

                )
                .then((response) => {

                    this.textSnack = "Gravado com sucesso!";
                    this.mostraMsg = true;
                    this.corMsg = "success";

                    this.mudaCampo("descricao");
                    this.limpaCampos();

                    this.buscaGrupos();
                })
                .catch((e) => {
                    this.loading = false;

                    if (!Array.isArray(e.response.data)) {

                        let itens = [];
                        for (var prop in e.response.data) {
                            itens.push(e.response.data[prop][0]);
                        }

                        this.textSnack = itens.join("");
                        this.mostraMsg = true;
                        this.corMsg = "error";
                    } else {

                        this.textSnack = e.response.data;

                        this.mostraMsg = true;
                        this.corMsg = "error";
                    }
                });

        }
    }
};
</script>
